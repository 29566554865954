import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faTrophy } from '@fortawesome/free-solid-svg-icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Awards, Projects & Certificates`}</h2>
    <p><FontAwesomeIcon className="text-warning" icon={faCertificate} mdxType="FontAwesomeIcon" /> Professional Scrum Master I Certified</p>
    <br />
    <ProjectCard title="Ask Intuit" link="https://devpost.com/software/ask-intuit" bg="linear-gradient(to right, #365EBF 0%, #36bf96 100%)" mdxType="ProjectCard">
  <h2><FontAwesomeIcon className="text-warning" icon={faTrophy} mdxType="FontAwesomeIcon" /></h2>
  <h6><a href="https://www.tarleton.edu/scripts/press/display.asp?id=5684">Intuit Challenge Winner</a>, Earth Hack 18'</h6>
  Ever asked Amazon Alexa questions about your taxes? Using AWS voice, a user can ask tax-related questions and the skill will parse the Intuit site for related Q&A and respond with the most appropriate.
  <br />
  <h6>Uses: Python, Flask, Beautiful Soup, React, AWS & Amazon Alexa</h6>
    </ProjectCard>
    <ProjectCard title="Social Credit" link="https://devpost.com/software/hackutd-sizryx" bg="linear-gradient(to right, #6bc843 0%, #43c8a0 100%)" mdxType="ProjectCard">
  <h2><FontAwesomeIcon className="text-warning" icon={faTrophy} mdxType="FontAwesomeIcon" /></h2>
  <h6>3rd Place @ HackUTD 18'</h6>
  Using Machine Learning linguistics, this Vue.js web app aims to help lenders analyze no-credit-history borrowers by using their social media presence for a "trust" score to determine a borrowers risk level.
  <h6>Uses: Vue.js, Python, Twitter API, Facebook API, Natural Language Processing</h6>
    </ProjectCard>
    <ProjectCard title="Hytch'd" link="https://github.com/brandonjurado/Ride-Hytch" bg="linear-gradient(to right, #e92929 0%, #df7499 100%)" mdxType="ProjectCard">
  <h2><FontAwesomeIcon className="text-warning" icon={faTrophy} mdxType="FontAwesomeIcon" /></h2>
  <h6>Earth Hack 17' Finalist</h6>
  A mobile Android app aiming to reduce the use of gas emissions with the idea of ride-sharing. Since the creation of this app very identical solutions have been implemented in Uber's ride-share and Waze.
  <h6>Uses: Android, Google Maps API, Java, Kotlin</h6>
    </ProjectCard>
    <ProjectCard title="Easy-AR Claims" link="https://devpost.com/software/easyar-claims" bg="linear-gradient(to right, #009245 0%, #00928e 100%)" mdxType="ProjectCard">
  <h6>HackDFW 19'</h6>
  A React-based web application created to allow users to take several pictures of their accident and transform it into a 3D image that can be shared with an insurance claims adjuster for viewing/rotating the image as if they were seeing it in person.
  <h6>Uses: React, OpenGL, React VR, SourceForge API</h6>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      