import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FaAngleRight } from 'react-icons/fa';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div>
    <div className="subheading">Additional Projects</div>
    <ul className="fa-ul mb-0">
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://web.archive.org/web/20180321081553/http://tsulambdachi.com/">
                TSU Lambda Chi
            </a> - 'About Us' site created for university organization (Wordpress/JS/HTML) 
        </li>
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://github.com/brandonjurado/Game-Browser">
                Game Browser
            </a> - A classic game directory using Ruby on Rails and JavaScript with AJAX 
        </li>
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://github.com/brandonjurado/Gone-Travel">
                Gone
            </a> - Travel app using PHP/Laravel framework 
        </li>
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://devpost.com/software/food-scrapper">
                Food Scrapper
            </a> - Informative React and Node.js web app 
        </li>
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://devpost.com/software/fantastic-mcmc">
                Fantastic MCMC
            </a> - Geographic web application using ExpressJS and Leaflet 
        </li>
        <li>
            <span className="fa-li"><FaAngleRight mdxType="FaAngleRight" /></span>
            <a href="https://devpost.com/software/covid19hack-ma4nh3">
                Socially Distanced Pantry 
            </a> - React app built for COVID-19 @ Earth Hack 2020 
        </li>
    </ul>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      