import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExperienceCard = makeShortcode("ExperienceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Experience`}</h2>
    <ExperienceCard title="Sr. Software Engineer" years="July 2023 - Present" company="H-E-B" relatedItems="Java, Dropwizard, Spring Boot, AWS Suite, TypeScript, PostgreSQL, Terraform, JUnit, Jest, GitLab CI/CD, Docker, Datadog, Postman
" bg="linear-gradient(to right, #DC291E 40%, ##C6251B 100%)" mdxType="ExperienceCard">
  Unexpected item in the coding area... As part of H-E-B Digital, I am actively contributing to the rapidly growing organization and building products related to Curbside Delivery.
    </ExperienceCard>
    <ExperienceCard title="Software Engineer" years="July 2021 - July 2023" company="T-Mobile" relatedItems="Java, Angular, AWS S3, Spring Boot, TypeScript, Okta, Cassandra, JUnit, GitLab CI/CD, Kubernetes, Conducktor, Postman, Splunk
" bg="linear-gradient(to right, #ED008C 40%, #999B9E 100%)" mdxType="ExperienceCard">
  Worked extensively with microservices to create an Ad-Hoc invoicing system solution to onboard large business line customers. Using Spring Boot services with a Cassandra persistence layer and AWS S3 storage, all presented to the user with an Angular frontend.
    </ExperienceCard>
    <ExperienceCard title="Software Engineer" years="July 2019 - July 2021" company="USAA" relatedItems="Java, Kafka, Spring [Actuator, Boot, Data JPA, Security, WebFlux], React, Groovy, DB2, CI/CD, Docker, Kubernetes, OpenShift, Kibana, Prometheus, Grafana, OAuth, Postman
" bg="linear-gradient(to right, #04334B 40%, #BCBDBD 100%)" mdxType="ExperienceCard">
  Created and Deployed several distributed microservices using Kafka, Spring WebFlux and Spring Data to store critical data to be used for analysis.
    </ExperienceCard>
    <ExperienceCard title="Software Developer" years="May 2018 - July 2019" company="American Airlines" relatedItems="Kotlin, Java, Redis, Spring [Actuator, Boot, Data JPA, Security, WebFlux], Angular, TypeScript, JUnit, Jenkins, Batch, IBM Bluemix, Splunk, Tealeaf, NewRelic, Selenium, OAuth, Postman
" bg="linear-gradient(to right, #0078D2 0%, #36495A 100%)" mdxType="ExperienceCard">
  Delivered quality software iteratively through DevOps pipeline as part of a Scrum team, using Jenkins for automated testing and deployment. Decomposed existing monolothic code base into Spring Boot microservices running in a cloud environment that is used by millions of users based on analytics. If you have ever booked a flight through AA.com you have definitely seen my work. 😄
    </ExperienceCard>
    <ExperienceCard title="Software Developer" years="May 2016 - August 2016" company="UTx @ The University of Texas System" relatedItems="Linux, Apache, MySQL, PHP, JSON" bg="linear-gradient(to right, #F47920 30%, #003767 100%)" mdxType="ExperienceCard">
  Developed internal web-based tools on LAMP stacks for tracking development projects, keeping the graphical user interface design in mind with HTML. Generated web services layer in PHP and a JSON message structure for consumption by the message architecture.  Additionally, defined and developed PCI compliant API structures.
    </ExperienceCard>
    <ExperienceCard title="Full Stack Rails Developer" years="February 2016 - May 2018" company="TIAER" relatedItems="Ruby on Rails, Selenium, RSpec, Jenkins, HTML, CSS, CoffeeScript, JavaScript, JQuery, Batch, MySQL, AWS, Linux, Mailchimp, CRON
" bg="linear-gradient(to right, #4F2D7F 30%, #FBB03B 100%)" mdxType="ExperienceCard">
  Managed the interchange of data between the server and the users. Focused on the development of all server-side logic and maintenance of the central database &amp servers, while ensuring high performance and responsiveness to requests from the front-end. Created and integrated the front-end elements into web applications and familiarized junior developers with MVC frameworks.
    </ExperienceCard>
    <ExperienceCard title="Quality Assurance Analyst (Intern)" years="February 2015 - February 2016" company="TIAER" relatedItems="HTML, CSS, JavaScript, Selenium, RSpec, Cucumber, MiniTest" bg="linear-gradient(to right, #D4145A 0%, #4F2D7F 100%)" mdxType="ExperienceCard">
  Provided end-to-end solutions to software quality problems by developing and executing exploratory and automated tests to ensure quality of web applications, while also handling front-end programming tasks. Designed and implemented tests, debugged and defined corrective actions. Conducted tests to ensure software ran smoothly and met client needs, while being cost-effective.
    </ExperienceCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      