import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Brandon Jurado`}</h1>
    <div className="subheading">Dallas, TX · <a href="mailto:hello@bjurado.com">hello@bjurado.com</a></div>
    <p className="lead">Multi-faceted software developer with a strong foundation in OOP concepts, leveraging skills acquired from several roles to provide robust contributions to applications. A quick learner that is self-motivated and highly driven to master new technologies.</p>
    <div className="social-icons">
  <a href="https://www.linkedin.com/in/brandonjurado/" className="social-icon"><FontAwesomeIcon icon={faLinkedinIn} mdxType="FontAwesomeIcon" /></a>
  <a href="https://github.com/brandonjurado/" className="social-icon"><FontAwesomeIcon icon={faGithub} mdxType="FontAwesomeIcon" /></a>
  <a href="https://twitter.com/BrandonjDev" className="social-icon"><FontAwesomeIcon icon={faTwitter} mdxType="FontAwesomeIcon" /></a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      