import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Interests`}</h2>
    <div className="interests">
  <p>While my professional life revolves around software engineering, my personal passions extend far beyond the confines of code. I thrive on outdoor adventures, cherishing an active and health-conscious lifestyle. In the winter, I head to the mountains, where I get my snowboarding fix, indulge in local eats, explore the landscapes, and enjoy disconnecting from the city.</p>
  <p>During the Texas summer heat when it's not so brutal, I embrace the great outdoors with mountain biking, state park explorations, and live music gigs. And when the weather keeps me indoors, my inner technologist comes to life. Sci-fi shows are my go-to for entertainment, and I'm an avid reader of technical literature, always seeking to expand my knowledge. To pass the time, I read tech discussions on forums like TechCrunch and Wired, and I stay connected with the latest tools through sites like Twitter for social engagement and FreeCodeCamp.</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      